import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "F:/web/active/infoamazonia-mercurio/src/components/reports/report-layout.js";
import Image from "!url-loader!../../images/fotos/suriname-900.jpg";
export const meta = {
  name: "suriname",
  title: "La fiebre del oro amenaza al país más verde del mundo",
  intro: "Las vías fluviales y los vientos de Surinam llevan el mercurio, un metal tóxico utilizado en la extracción de oro, a regiones muy alejadas de las minas de oro del país.",
  by: "por Bram Ebus (textos y fotografia) y Wilfred Leeuwin (textos). Vídeos por Tom Laffay",
  image: Image,
  next: {
    slug: "venezuela",
    title: "La fiebre del oro amenaza al país más verde del mundo",
    description: "Las vías fluviales y los vientos de Surinam llevan el mercurio, un metal tóxico utilizado en la extracción de oro, a regiones muy alejadas de las minas de oro del país."
  }
};
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Gallery = makeShortcode("Gallery");
const Video = makeShortcode("Video");
const Sidenote = makeShortcode("Sidenote");
const layoutProps = {
  meta,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p><strong parentName="p">{`Hombres de Surinam con`}</strong>{` ritmo acelerado, cargando bolsos deportivos atestados de oro, cruzan la entrada de una de las tiendas de oro en el centro de Paramaribo, la capital de Surinam. El vigilante, un hombre con lentes de sol y shorts, mira desde el estacionamiento, con la mano apoyada en el bolso que lleva cruzado en el pecho. En el interior, se puede escuchar a chinos, brasileños, surinameses y holandeses mientras los kilogramos de oro cambian de manos. Nadie pregunta a los hombres de dónde se extrajo el oro, antes de que salgan de la tienda; ahora los bolsos ahora están llenos de dinero.`}</p>
    <p>{`En el mostrador, Harry Souza*, un brasileño musculoso, muestra sus tatuajes y un pequeño cubo de metal lleno de barras de oro de diferentes tamaños y colores. Incluso hay algunas “esponjas”, otra manera de llamar la amalgama de mercurio y oro que viene directamente de las minas y parece un lujoso arrecife de coral. “Cuanto más rojo es, más alta es la calidad”, dice Souza. “Cuanto más verde o negro, menos `}{`[valioso]`}{`”. En la trastienda del local de oro, un soplete arroja llamas, y Souza regresa para quemar el mercurio sobrante bajo una campana de extracción. La cantidad de oro que queda en el recipiente pesa aproximadamente un kilo, un valor de mercado de más de US$62.000 (precio en 4 de septiembre).`}</p>
    <Gallery mdxType="Gallery">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAECAwQF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAeFfmlKhCf/EABYQAAMAAAAAAAAAAAAAAAAAAAAgMv/aAAgBAQABBQIlf//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/ARn/xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPwFJ/8QAFxABAAMAAAAAAAAAAAAAAAAAAQAgIf/aAAgBAQAGPwKaFf/EABoQAAMAAwEAAAAAAAAAAAAAAAABERAhMYH/2gAIAQEAAT8hk4NtFVfRvfEUuP/aAAwDAQACAAMAAAAQzP8A/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8Qir//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPxCiP//EABoQAQEBAQADAAAAAAAAAAAAAAERACFBUWH/2gAIAQEAAT8QEirTp5MEx1HmGtIfDXd6Zl3/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/a4264e07ca9280913466a07557b380e2/e93cc/1.webp 300w", "/static/a4264e07ca9280913466a07557b380e2/b0544/1.webp 600w", "/static/a4264e07ca9280913466a07557b380e2/68fc1/1.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/a4264e07ca9280913466a07557b380e2/73b64/1.jpg 300w", "/static/a4264e07ca9280913466a07557b380e2/3ad8d/1.jpg 600w", "/static/a4264e07ca9280913466a07557b380e2/6c738/1.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/a4264e07ca9280913466a07557b380e2/6c738/1.jpg",
              "alt": "Gold mining is the driving force of the Surinamese economy",
              "title": "Gold mining is the driving force of the Surinamese economy",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`Gold mining is the driving force of the Surinamese economy`}</em></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEEAgX/xAAVAQEBAAAAAAAAAAAAAAAAAAACAf/aAAwDAQACEAMQAAAB41USNyMR/8QAGRAAAwADAAAAAAAAAAAAAAAAAAECAxAR/9oACAEBAAEFAlDZDnEibqRvuv/EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/ARn/xAAWEQEBAQAAAAAAAAAAAAAAAAABEBH/2gAIAQIBAT8BTZ//xAAaEAACAgMAAAAAAAAAAAAAAAABIQIQABFB/9oACAEBAAY/Ak8nGYZGnykb/8QAHBABAAEEAwAAAAAAAAAAAAAAAQAQESFhMVFx/9oACAEBAAE/IeMK0TqswoQu55FyYOif/9oADAMBAAIAAwAAABDLP//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/EIH/xAAVEQEBAAAAAAAAAAAAAAAAAAAQMf/aAAgBAgEBPxCA/8QAHBABAAMAAgMAAAAAAAAAAAAAAQARMSFhUXGB/9oACAEBAAE/EHAW4WEcy4Cq68RRV7liGGqou8i6vK/YIVd7J//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/f59851e8320e6e8a295af8ef7f22cf0c/e93cc/2.webp 300w", "/static/f59851e8320e6e8a295af8ef7f22cf0c/b0544/2.webp 600w", "/static/f59851e8320e6e8a295af8ef7f22cf0c/68fc1/2.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/f59851e8320e6e8a295af8ef7f22cf0c/73b64/2.jpg 300w", "/static/f59851e8320e6e8a295af8ef7f22cf0c/3ad8d/2.jpg 600w", "/static/f59851e8320e6e8a295af8ef7f22cf0c/6c738/2.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/f59851e8320e6e8a295af8ef7f22cf0c/6c738/2.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwAF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAHNUZzOaK//xAAZEAACAwEAAAAAAAAAAAAAAAAAAQISIQP/2gAIAQEAAQUC2TXKpYkR0aTP/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAEhMf/aAAgBAwEBPwGMU//EABYRAAMAAAAAAAAAAAAAAAAAAAIQMf/aAAgBAgEBPwEqv//EABoQAAICAwAAAAAAAAAAAAAAAAARAjEBECH/2gAIAQEABj8CVDlLfcFI/8QAGhABAAMAAwAAAAAAAAAAAAAAAQARITFBYf/aAAgBAQABPyG8IMxUiO+YKLGpTYL7EsPTJ//aAAwDAQACAAMAAAAQ9N//xAAXEQEBAQEAAAAAAAAAAAAAAAABABEh/9oACAEDAQE/ECHFs//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/EEs//8QAGxABAAIDAQEAAAAAAAAAAAAAAQARITFBYXH/2gAIAQEAAT8QEigXnKwDZJsQr1uBYAg4Q3EN6DZ2J6y+oeRgw6rs/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1955505b6f7aa1de89f88179df623995/e93cc/DSC07454.webp 300w", "/static/1955505b6f7aa1de89f88179df623995/b0544/DSC07454.webp 600w", "/static/1955505b6f7aa1de89f88179df623995/68fc1/DSC07454.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1955505b6f7aa1de89f88179df623995/73b64/DSC07454.jpg 300w", "/static/1955505b6f7aa1de89f88179df623995/3ad8d/DSC07454.jpg 600w", "/static/1955505b6f7aa1de89f88179df623995/6c738/DSC07454.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/1955505b6f7aa1de89f88179df623995/6c738/DSC07454.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAEDBv/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHP2i4BFf/EABkQAAIDAQAAAAAAAAAAAAAAAAECAAMQEf/aAAgBAQABBQKLW7jOnP/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABcRAAMBAAAAAAAAAAAAAAAAAAEQEUH/2gAIAQIBAT8BNxf/xAAYEAACAwAAAAAAAAAAAAAAAAAAEAEiMf/aAAgBAQAGPwIrD1f/xAAZEAEAAwEBAAAAAAAAAAAAAAABABARMYH/2gAIAQEAAT8hm4VOTjQBgj2v/9oADAMBAAIAAwAAABD0z//EABcRAAMBAAAAAAAAAAAAAAAAAAEQEUH/2gAIAQMBAT8QE1f/xAAVEQEBAAAAAAAAAAAAAAAAAAAQIf/aAAgBAgEBPxCA/8QAGhABAQACAwAAAAAAAAAAAAAAAREAECExQf/aAAgBAQABPxDjHNqAh7iKDKM06EPYIuv/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1fd95edea965a3eb4196ffd09162346f/e93cc/4.webp 300w", "/static/1fd95edea965a3eb4196ffd09162346f/b0544/4.webp 600w", "/static/1fd95edea965a3eb4196ffd09162346f/68fc1/4.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1fd95edea965a3eb4196ffd09162346f/73b64/4.jpg 300w", "/static/1fd95edea965a3eb4196ffd09162346f/3ad8d/4.jpg 600w", "/static/1fd95edea965a3eb4196ffd09162346f/6c738/4.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/1fd95edea965a3eb4196ffd09162346f/6c738/4.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAci0GJCh/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAECEQMx/9oACAEBAAEFApnS4xeHOmm2zT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAZEAEAAgMAAAAAAAAAAAAAAAAAETEBECH/2gAIAQEABj8CVGVuJ1//xAAbEAEAAwADAQAAAAAAAAAAAAABABEhQVFxkf/aAAgBAQABPyEsfKmAntILgR5HSqairSrL8T//2gAMAwEAAgADAAAAEFMP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGhABAAMBAQEAAAAAAAAAAAAAAQARITFBkf/aAAgBAQABPxAEqquBsBOoaD84ksgV+qJXgFsESHVY3vBP/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/bf4a286d8b2c8d7f810a0a89817d0221/e93cc/5.webp 300w", "/static/bf4a286d8b2c8d7f810a0a89817d0221/b0544/5.webp 600w", "/static/bf4a286d8b2c8d7f810a0a89817d0221/68fc1/5.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/bf4a286d8b2c8d7f810a0a89817d0221/73b64/5.jpg 300w", "/static/bf4a286d8b2c8d7f810a0a89817d0221/3ad8d/5.jpg 600w", "/static/bf4a286d8b2c8d7f810a0a89817d0221/6c738/5.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/bf4a286d8b2c8d7f810a0a89817d0221/6c738/5.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
    </Gallery>
    <p>{`La minería del oro es la fuerza motriz de la economía de Surinam, un pequeño país en la esquina noreste de América del Sur. Oficialmente, Surinam produjo alrededor de 32.800 kilogramos de oro en el 2019, y más de la mitad de ese oro provino de la minería artesanal y en pequeña escala. El oro representa más del 80% de los ingresos de Surinam por exportaciones.`}</p>
    <p>{`Tal como en Guyana, la industria aurífera de Surinam está apoyada por un mercado negro de mercurio, el metal tóxico utilizado en el proceso de extracción. El país utiliza más de 50 toneladas de mercurio al año, y los expertos creen que ahora todo entra en el país de forma ilegal. La contaminación por mercurio es desenfrenada en todo el país, desde la capital en la costa hasta las remotas cuencas fluviales en el interior; pero sin la ayuda del gobierno, los mineros artesanales deben elegir a menudo entre apoyar las redes de tráfico de mercurio o perder sus medios de subsistencia.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Tal como en Guyana, la industria aurífera de Surinam está apoyada por un mercado negro de mercurio`}</p>
    </blockquote>
    <iframe src="https://datawrapper.dwcdn.net/rUFBE/2/"></iframe>
    <hr></hr>
    <p>{`En las imágenes satelitales, Surinam es casi totalmente verde. La selva cubre el 93% del país, convirtiéndolo en una de las naciones más selváticas del mundo; sin embargo, una moderna fiebre del oro está amenazando las exuberantes selvas del país.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Los proyectos de extracción de oro legales e ilegales se han convertido en el mayor contribuyente a la deforestación en Surinam.`}</p>
    </blockquote>
    <p>{`Muchas comunidades a lo largo y ancho de la selva dependen de la minería, incluyendo a los cimarrones, descendientes de los africanos esclavizados que escaparon de las plantaciones holandesas de la costa y se asentaron en el interior, quienes tienen fuertes conexiones culturales con la minería y el oro. El aumento de los precios del oro a principios del siglo XXI ha dado lugar a una vertiginosa expansión de la industria de la minería del oro, especialmente en el territorio cimarrón. Los proyectos de extracción de oro legales e ilegales se han convertido en el mayor contribuyente a la deforestación en Surinam. La deforestación anual en Surinam aumentó en un 12% en el 2018 ―el quinto mayor aumento de la tasa de deforestación en el mundo.`}</p>
    <Video url="https://youtube.com/embed/B-6C1u8KqHw" mdxType="Video" />
    <p>{`La huella de la industria es mayor en los asentamientos de fácil acceso, como es el caso de Brownsweg, un pequeño pueblo cimarrón en el interior de Surinam. La mayoría de la población se dedica a la minería. En las noches, unos pocos mineros adinerados se presentan para festejar frente a los supermercados y licorerías chinas, derrapándose con giros de 360 grados con sus Lexus en la selva; pero la mayoría son mineros de subsistencia y luchan para llegar a fin de mes.`}</p>
    <p>{`En una mina ubicada a una hora en las afueras de Brownsweg, uno de los líderes de la comunidad cimarrona local, llamados capitanes, camina alrededor de los cráteres ocres que marcan la selva verde. Luciendo chancletas y un Pangi, el tradicional paño cimarrón, que él lleva sobre su hombro derecho, “Capy” explica que sus mineros utilizan maquinaria pesada para cavar las fosas, pero necesitan mercurio para separar el oro del barro.`}</p>
    <Gallery mdxType="Gallery">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAUE/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAdsljlqI4//EABsQAAIDAAMAAAAAAAAAAAAAAAADAQIRBBMj/9oACAEBAAEFAnM86tsq082wyew2TD//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPwGH/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAHBAAAgEFAQAAAAAAAAAAAAAAAAFBEBESIVGR/9oACAEBAAY/Ankl6cINwWp//8QAGxABAAMAAwEAAAAAAAAAAAAAAQARITFBUWH/2gAIAQEAAT8hA5iVnaLAovhkA4RewHwTJtYTZdz/2gAMAwEAAgADAAAAEOw//8QAFxEAAwEAAAAAAAAAAAAAAAAAACExUf/aAAgBAwEBPxBKLD//xAAWEQEBAQAAAAAAAAAAAAAAAAAAESH/2gAIAQIBAT8Qta//xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhMVGB/9oACAEBAAE/EA1Jwdq5Qw7dlRs5XyHSqBvbfexNHCmBm8jckhoWwgCsLJ//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/ebb05ccd492c268a66c762bf7d8cafa3/e93cc/slider2-1.webp 300w", "/static/ebb05ccd492c268a66c762bf7d8cafa3/b0544/slider2-1.webp 600w", "/static/ebb05ccd492c268a66c762bf7d8cafa3/68fc1/slider2-1.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/ebb05ccd492c268a66c762bf7d8cafa3/73b64/slider2-1.jpg 300w", "/static/ebb05ccd492c268a66c762bf7d8cafa3/3ad8d/slider2-1.jpg 600w", "/static/ebb05ccd492c268a66c762bf7d8cafa3/6c738/slider2-1.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/ebb05ccd492c268a66c762bf7d8cafa3/6c738/slider2-1.jpg",
              "alt": "In easily accessible settlements like Brownsweg, a small Maroon village in Suriname’s interior. A majority of the population is involved in mining",
              "title": "In easily accessible settlements like Brownsweg, a small Maroon village in Suriname’s interior. A majority of the population is involved in mining",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`In easily accessible settlements like Brownsweg, a small Maroon village in Suriname’s interior. A majority of the population is involved in mining`}</em></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAwAEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAEU5uyaSGH/xAAbEAADAAIDAAAAAAAAAAAAAAAAAQIDEgQTFP/aAAgBAQABBQL12Lk2duVikl6m7P/EABYRAQEBAAAAAAAAAAAAAAAAAAARAf/aAAgBAwEBPwGJj//EABYRAQEBAAAAAAAAAAAAAAAAAAARAf/aAAgBAgEBPwGrr//EABwQAAIABwAAAAAAAAAAAAAAAAAQASExQlGBkf/aAAgBAQAGPwK0pDhjSkv/xAAcEAEBAAICAwAAAAAAAAAAAAABABEhMUFRgbH/2gAIAQEAAT8hGQ+JOlvadwwPEFecSHrKX//aAAwDAQACAAMAAAAQxO//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPxALL//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/EFkv/8QAHRABAAICAgMAAAAAAAAAAAAAAQARITFBcZHR8f/aAAgBAQABPxBaAU3f3EEOF4THmL2o18WYEqbrmKhYD3Fbd9z/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/b1d5a36bb569a43f9c3403275a2f4e34/e93cc/slider2-2.webp 300w", "/static/b1d5a36bb569a43f9c3403275a2f4e34/b0544/slider2-2.webp 600w", "/static/b1d5a36bb569a43f9c3403275a2f4e34/68fc1/slider2-2.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/b1d5a36bb569a43f9c3403275a2f4e34/73b64/slider2-2.jpg 300w", "/static/b1d5a36bb569a43f9c3403275a2f4e34/3ad8d/slider2-2.jpg 600w", "/static/b1d5a36bb569a43f9c3403275a2f4e34/6c738/slider2-2.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/b1d5a36bb569a43f9c3403275a2f4e34/6c738/slider2-2.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAED/9oADAMBAAIQAxAAAAGZrmSrAaIn/8QAGRABAAMBAQAAAAAAAAAAAAAAAQACEiEx/9oACAEBAAEFAihMgFOEfNtZ/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8Bh//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABkQAAIDAQAAAAAAAAAAAAAAAAAxARAhEf/aAAgBAQAGPwJCIysk4z//xAAaEAEAAgMBAAAAAAAAAAAAAAABABEhMVFx/9oACAEBAAE/Ia8LLxyWJRsniBGUSgYYbSf/2gAMAwEAAgADAAAAEKv/AP/EABcRAAMBAAAAAAAAAAAAAAAAAAABEWH/2gAIAQMBAT8QSQmH/8QAFhEAAwAAAAAAAAAAAAAAAAAAEBEh/9oACAECAQE/EKx//8QAGhABAQEAAwEAAAAAAAAAAAAAAREAITGRsf/aAAgBAQABPxCdeU9140E9PNRHUWT5mPQOvmam5aF1GIDgV3//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/7e7ac88459187d99aaa9ace549d0e89b/e93cc/slider2-3.webp 300w", "/static/7e7ac88459187d99aaa9ace549d0e89b/b0544/slider2-3.webp 600w", "/static/7e7ac88459187d99aaa9ace549d0e89b/68fc1/slider2-3.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/7e7ac88459187d99aaa9ace549d0e89b/73b64/slider2-3.jpg 300w", "/static/7e7ac88459187d99aaa9ace549d0e89b/3ad8d/slider2-3.jpg 600w", "/static/7e7ac88459187d99aaa9ace549d0e89b/6c738/slider2-3.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/7e7ac88459187d99aaa9ace549d0e89b/6c738/slider2-3.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBAgT/xAAVAQEBAAAAAAAAAAAAAAAAAAACAf/aAAwDAQACEAMQAAABmzMIYLKf/8QAGRAAAgMBAAAAAAAAAAAAAAAAAAECAxET/9oACAEBAAEFAujO2krZJtFsEbh//8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAS/9oACAEDAQE/AcBZL//EABcRAAMBAAAAAAAAAAAAAAAAAAABEgL/2gAIAQIBAT8BtlaP/8QAHRAAAQIHAAAAAAAAAAAAAAAAAAIREBIhMkGRof/aAAgBAQAGPwKqOjS7LUwyMf/EABoQAQACAwEAAAAAAAAAAAAAAAEAESExcZH/2gAIAQEAAT8hvIKEBxFOr9ZabikVUwm3Z//aAAwDAQACAAMAAAAQU+//xAAWEQADAAAAAAAAAAAAAAAAAAABECH/2gAIAQMBAT8QNCv/xAAXEQEAAwAAAAAAAAAAAAAAAAAAESFh/9oACAECAQE/EJ6bP//EAB0QAQEAAgIDAQAAAAAAAAAAAAERACExQVFhgaH/2gAIAQEAAT8QrNY9cc2TPIHuc45VBYMPtwZ6PbDeL/GkoB+YMgCGnln/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/357b3d16b1f9dcbf61dd30c120d707c9/e93cc/slider2-4.webp 300w", "/static/357b3d16b1f9dcbf61dd30c120d707c9/b0544/slider2-4.webp 600w", "/static/357b3d16b1f9dcbf61dd30c120d707c9/68fc1/slider2-4.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/357b3d16b1f9dcbf61dd30c120d707c9/73b64/slider2-4.jpg 300w", "/static/357b3d16b1f9dcbf61dd30c120d707c9/3ad8d/slider2-4.jpg 600w", "/static/357b3d16b1f9dcbf61dd30c120d707c9/6c738/slider2-4.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/357b3d16b1f9dcbf61dd30c120d707c9/6c738/slider2-4.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAgP/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAv/aAAwDAQACEAMQAAABrKec04JB/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAESAhET/9oACAEBAAEFAvXZe2OyelMeun//xAAXEQEAAwAAAAAAAAAAAAAAAAAAARIh/9oACAEDAQE/Aawx/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAESIf/aAAgBAgEBPwG0tf/EABkQAAIDAQAAAAAAAAAAAAAAAAABIDIzQv/aAAgBAQAGPwKqRyjSH//EABoQAQADAAMAAAAAAAAAAAAAAAEAESExUWH/2gAIAQEAAT8h1OV3EV3tRkpdB5UB5e5iF8NxVbP/2gAMAwEAAgADAAAAEJAP/8QAFxEBAAMAAAAAAAAAAAAAAAAAABFRYf/aAAgBAwEBPxDBFH//xAAYEQACAwAAAAAAAAAAAAAAAAAAARFBUf/aAAgBAgEBPxBOsnZ//8QAGxABAQACAwEAAAAAAAAAAAAAAREAITFBUaH/2gAIAQEAAT8QqXKwtVl5zkZUU18xtEexE+YUpQ32wl+vXezJ93Pc/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1211945c12409677b43af1b702c5ee14/e93cc/slider2-5.webp 300w", "/static/1211945c12409677b43af1b702c5ee14/b0544/slider2-5.webp 600w", "/static/1211945c12409677b43af1b702c5ee14/68fc1/slider2-5.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1211945c12409677b43af1b702c5ee14/73b64/slider2-5.jpg 300w", "/static/1211945c12409677b43af1b702c5ee14/3ad8d/slider2-5.jpg 600w", "/static/1211945c12409677b43af1b702c5ee14/6c738/slider2-5.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/1211945c12409677b43af1b702c5ee14/6c738/slider2-5.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
    </Gallery>
    <Sidenote side="left" title="" mdxType="Sidenote">
	Alrededor del 98% de los mineros de Surinam usan mercurio, que se une a pequeñas partículas de oro mezcladas con el agua y el lodo.
    </Sidenote>
    <p>{`El mercurio es el que engrasa los engranajes de las economías mineras locales. Alrededor del 98% de los mineros de Surinam usan mercurio, que se une a pequeñas partículas de oro mezcladas con el agua y el lodo que sale de las minas. Sin el mercurio líquido, las minas artesanales no podrían extraer eficientemente el oro enclavado en el suelo de la selva, señala Jessica Naarendorp, directora financiera de NANA Resources, una empresa surinamesa que anteriormente extraía oro, pero que ahora explora principalmente para empresas mineras nacionales y extranjeras.`}</p>
    <p>{`Por cada kilogramo de oro que se extrae, se utilizan aproximadamente tres kilogramos de mercurio y, de estos, la mayoría se libera en el frágil ecosistema amazónico.`}</p>
    <hr></hr>
    <p>{`A más de 100 kilómetros al norte de la mina de Capy, Paul Ouboter, biólogo y director del `}<a parentName="p" {...{
        "href": "https://www.neowild.org/"
      }}>{`Instituto de Vida Silvestre Neotropical y Estudios Ambientales`}</a>{` (también conocido como NeoWild) en Surinam, se sienta en la terraza de su casa en Paramaribo. Traza el camino de las vías fluviales del país en los mapas de la pantalla de su portátil. Gran parte del mercurio liberado por el sector minero termina acumulándose en los ríos de la región, según indica Ouboter, y desde allí, viaja por la cadena alimenticia. Las comunidades indígenas como la cimarrona, que dependen del pescado para gran parte de su dieta, están expuestas a niveles de mercurio particularmente altos.`}</p>
    <Video url="https://youtube.com/embed/-0sUAudv6CA" mdxType="Video" />
    <p>{`La investigación muestra que casi la mitad de los peces depredadores capturados en su hábitat natural en Surinam tienen altos niveles de mercurio. “Eso es un problema porque a la gente le gusta comer peces grandes”, sonríe. “No es fácil cambiar los hábitos alimenticios de las personas, pero eso es lo que tenemos que hacer”. Ouboter advierte que se deberían evitar el pez lobo gigante, las pirañas y varios bagres del menú de Surinam.`}</p>
    <p>{`Pero el mercurio no solo se transporta a través del agua y los peces, sino que también viaja por el aire después de evaporarse de la superficie del agua y la vegetación o después de que los mineros queman el mercurio de la amalgama en campo. “Lo que sucede entonces es que va con los vientos alisios del noreste hacia el oeste y luego vuelve a precipitarse, sobre todo cuando llueve”, dice Ouboter. El mercurio transportado por el aire puede llegar a regiones sin ninguna minería de oro, como la cuenca superior del río Coppename.`}</p>
    <p>{`Ouboter exige una prohibición inmediata del mercurio, aunque advierte que es imposible revertir el daño causado a los sistemas fluviales. El mercurio que ya está en el ecosistema permanecerá allí durante siglos.`}</p>
    <hr></hr>
    <p>{`Capy, el minero de Brownsweg, es consciente del impacto ambiental de su operación, pero afirma que no es nada comparado con las corporaciones internacionales que cavan más profundo que los mineros de pequeña y mediana escala y aplican cianuro tóxico en su proceso de extracción. La contaminación por cianuro, aunque no tan persistente como la del mercurio, sigue siendo una gran amenaza para la vida salvaje y la salud humana. “A mi modo de ver, el gobierno tiene muchos intereses en estas grandes empresas”, dice, “por lo que no les importa”.`}</p>
    <Video url="https://youtube.com/embed/ii6Bi2Zy3Ng" mdxType="Video" />
    <p>{`Las dos grandes multinacionales mineras extranjeras de Surinam, IAMGOLD y Newmont Mining, utilizan un proceso llamado lixiviación en pilas con cianuro, que puede extraer concentraciones más finas de oro del suelo. Con estos métodos avanzados, los inversores extranjeros pueden trabajar en terrenos previamente explotados por los cimarrones del interior, quienes solo pueden obtener alrededor del 30% del oro disponible.`}</p>
    <p>{`El capitán cimarrón se queja de que las minas no son la única fuente de contaminación por mercurio. Las tiendas de oro en Paramaribo, argumenta, usan las mismas técnicas para quemar el mercurio y aislar el oro. “En la ciudad piensan que los residentes del interior no somos buenos, nada de lo que hacemos es bueno”, dice. “Pero cuando sacamos nuestro oro a la ciudad, allí ocurre lo mismo, y nadie puede decirme que allí no es dañino.”`}</p>
    <blockquote>
      <p parentName="blockquote">{`“En la ciudad piensan que los residentes del interior no somos buenos, nada de lo que hacemos es bueno”`}</p>
    </blockquote>
    <hr></hr>
    <p>{`La pequeña pero bulliciosa capital de Surinam, Paramaribo, es un crisol de etnias. Las viejas casas coloniales holandesas y una cocina internacional hacen de la ciudad un destino para los turistas que, en su mayoría, llegan en vuelos directos desde los Países Bajos. A pesar de su población pequeña de 586.000 habitantes, el país no tiene una etnia dominante. Los trabajadores indios y chinos - traídos después del fin de la esclavitud en 1863 - ahora constituyen más de un cuarto de la población de Surinam. El idioma oficial es el holandés, y el idioma común es el Sranan Tongo, un criollo con base en el holandés, el inglés y el portugués, pero el portugués domina al norte de la ciudad, un barrio llamado Little Belem `}{`[pequeña Belén]`}{`, llamado así por una ciudad brasileña situada a más de 1.000 km de Paramaribo.`}</p>
    <Video url="https://youtube.com/embed/R4kwzxuetgE" mdxType="Video" />
    <p>{`Little Belem tiene muchos supermercados brasileños y tiendas que anuncian ‘compra de ouro’ ―compra de oro en portugués. La comunidad migratoria se construyó sobre décadas de migración laboral de brasileños en busca de mejores perspectivas económicas. La mayoría de ellos encontraron trabajo como garimpeiros - mineros ilegales de oro - o dirigiendo el comercio en las tiendas de oro de Paramaribo, que vierten vapor de mercurio al aire sobre la ciudad desde las chimeneas de los tejados.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“En Paramaribo hemos visto mediciones que son de seis a doce veces más altas que las de los yacimientos de oro”`}</p>
    </blockquote>
    <p>{`Dennis Wip, profesor titular de la Universidad de Surinam especializado en la contaminación por mercurio, advierte que estas chimeneas pueden asesinar lentamente.`}</p>
    <p>{`“La contaminación por mercurio es un gran problema en Surinam”, dice Wip, y no solo en sitios mineros remotos, como mucha gente supone. “En la ciudad de Paramaribo, la contaminación es bastante alta”, dice. De acuerdo al científico, las emisiones de las tiendas de oro en ocasiones elevan los niveles de mercurio atmosférico en la ciudad hasta 30.000 nanogramos por metro cúbico, significativamente más alto de lo que las normas de la Unión Europea y los Estados Unidos de América consideran seguro. “En Paramaribo hemos visto mediciones que son de seis a doce veces más altas que las de los yacimientos de oro”, dice. “Eso significa que tenemos que ocuparnos del negocio del oro en la ciudad”.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAECAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB4zdxiSL/AP/EABgQAAMBAQAAAAAAAAAAAAAAAAABAhEi/9oACAEBAAEFAsTdYLCUVHOH/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8BV//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABcQAAMBAAAAAAAAAAAAAAAAAAAQESH/2gAIAQEABj8CMdq//8QAGhAAAwEAAwAAAAAAAAAAAAAAAAERMWFxof/aAAgBAQABPyFVU89HibEjDlIJodj/2gAMAwEAAgADAAAAEPvf/8QAFhEBAQEAAAAAAAAAAAAAAAAAABFR/9oACAEDAQE/EEY//8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8QR//EABsQAQEBAAMBAQAAAAAAAAAAAAERADFBUSFx/9oACAEBAAE/EKoHKIv5Jn0JRzo9S9t5xqxCRHzAUkemV8nXf//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/eb9ccd46747fc591122a9a0c36a72dcf/e93cc/DSC07471.webp 300w", "/static/eb9ccd46747fc591122a9a0c36a72dcf/b0544/DSC07471.webp 600w", "/static/eb9ccd46747fc591122a9a0c36a72dcf/68fc1/DSC07471.webp 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/eb9ccd46747fc591122a9a0c36a72dcf/73b64/DSC07471.jpg 300w", "/static/eb9ccd46747fc591122a9a0c36a72dcf/3ad8d/DSC07471.jpg 600w", "/static/eb9ccd46747fc591122a9a0c36a72dcf/6c738/DSC07471.jpg 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/eb9ccd46747fc591122a9a0c36a72dcf/6c738/DSC07471.jpg",
            "alt": "Emissions from the gold shops on occasion drive atmospheric mercury levels in Paramaribo",
            "title": "Emissions from the gold shops on occasion drive atmospheric mercury levels in Paramaribo",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`Emissions from the gold shops on occasion drive atmospheric mercury levels in Paramaribo`}</em></p>
    <p>{`Los equipos de las tiendas de oro, principalmente las retortas, que son dispositivos de captura de mercurio, suelen ser disfuncionales o estar en mal estado, según Wip. La mayoría de los empleados de las tiendas de oro, consultados para este informe, no eran conscientes de los daños de la contaminación por mercurio o restaron importancia a su impacto, lo que los expertos atribuyen a la “cultura machista” de la industria, la falta de acceso a recursos médicos y la naturaleza de acción retardada de la toxina.`}</p>
    <p>{`Sin un mejor equipo y educación sobre los riesgos, las emisiones de mercurio en Paramaribo podrían seguir aumentando a medida que los precios del oro siguen en alza, y cada vez más del metal precioso llega a las tiendas de oro de la capital.`}</p>
    <hr></hr>
    <p>{`En el 2018, Surinam ratificó el Convenio de Minamata sobre el mercurio, un tratado mundial destinado a reducir la contaminación por mercurio, en parte eliminando su uso en la minería de oro. No obstante, el mercurio sigue llegando a través de sus fronteras, principalmente por canales ilegales; si bien el mercurio se puede importar legalmente bajo licencia, no se han concedido permisos desde 2004.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.iucn.nl/-files/groene_economie/regional_mercury_report_suriname.pdf"
      }}>{`Un estimado de 56 toneladas`}</a>{` de mercurio se utilizaron en Surinam en el 2018, el año más reciente del que se disponen datos, y varias ferreterías mineras, dirigidas por propietarios brasileños, indios y chinos, confirman que pueden proveer mercurio líquido a un cliente. “La amplia disponibilidad de mercurio en Surinam y el hecho de que no se haya producido ninguna importación legal de mercurio en más de 15 años hace sospechar que todo el mercurio del mercado entró en el país de forma ilegal”, dice Marieke Heemskerk, antropóloga con una larga trayectoria en la investigación del sector aurífero de Surinam.`}</p>
    <p>{`Ninguno de los propietarios de las tiendas quiso divulgar cómo adquirieron el mercurio en primer lugar, pero resulta que no es difícil de encontrar si se sabe a quién preguntar.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDAQT/xAAWAQEBAQAAAAAAAAAAAAAAAAACAAH/2gAMAwEAAhADEAAAAeLFbHImR//EABgQAQEBAQEAAAAAAAAAAAAAAAECEgAR/9oACAEBAAEFApjUO5PThzS+yrPf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGhAAAgMBAQAAAAAAAAAAAAAAAAERIUEQIv/aAAgBAQAGPwLy+WmVo5xkJn//xAAaEAEAAgMBAAAAAAAAAAAAAAABACERMVFh/9oACAEBAAE/IcMgMxnl27Jxb5EJTayPVPY3GcrG5//aAAwDAQACAAMAAAAQE/8A/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8QV//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPxCVj//EABwQAQEAAgIDAAAAAAAAAAAAAAERACExQVFhof/aAAgBAQABPxBzIcnnDe5WgSEzUaNsNHB/iAP3J5WVDRZk+DIHr1n/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/b7982542053e6174008af7df5977506e/e93cc/DSC07613.webp 300w", "/static/b7982542053e6174008af7df5977506e/b0544/DSC07613.webp 600w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/b7982542053e6174008af7df5977506e/73b64/DSC07613.jpg 300w", "/static/b7982542053e6174008af7df5977506e/3ad8d/DSC07613.jpg 600w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/b7982542053e6174008af7df5977506e/3ad8d/DSC07613.jpg",
            "alt": "Wrapped inside a newspaper a mercury dealer reveals a small, white bottle filled with a heavy and silvery liquid. “It comes from Guyana,” the man says.",
            "title": "Wrapped inside a newspaper a mercury dealer reveals a small, white bottle filled with a heavy and silvery liquid. “It comes from Guyana,” the man says.",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`Wrapped inside a newspaper a mercury dealer reveals a small, white bottle filled with a heavy and silvery liquid. “It comes from Guyana,” the man says.`}</em></p>
    <p>{`Aproximadamente a las seis de la tarde, los bares cerca de las tiendas de oro de Little Belem y las calles de Paramaribo empiezan a ser más ruidosos a medida que el sol se oculta en la ciudad. Un taxista indio bebe nerviosamente de una lata de medio litro de cerveza Heineken mientras se queda al lado de su taxi en la Anamoestraat, la calle principal de Little Belem. Cuando se le pregunta por el mercurio líquido, se ofrece a conducir hasta una gasolinera cercana para hacer una rápida llamada telefónica a un distribuidor. Cuelga y anuncia que un kilogramo de mercurio costará $110 y que un vendedor está en camino.`}</p>
    <p>{`En pocos minutos aparece otro automóvil. El conductor baja la ventanilla y sostiene un periódico enrollado que ha traído consigo. “Viene de Guyana”, dice el hombre. Dentro hay una pequeña botella blanca llena de un líquido pesado y plateado. El traficante desenrosca la botella de plástico y vierte una pequeña cantidad de mercurio en la tapa de la botella para demostrar que tiene el producto. Cuando se le pregunta cuánto más mercurio podría conseguir, pregunta: “¿Cuánto querría?” No parece haber escasez de él y no se ha incautado ni un solo transporte de mercurio en ruta desde Guyana desde el 2014.`}</p>
    <hr></hr>
    <p>{`No todo el mercurio ilícito del país proviene de Guyana, indica una fuente de Paramaribo, quien accedió a una entrevista con la condición de que solo se le identificara como un “empresario del oro” anónimo. En sus viajes regulares al sur para trabajar en las minas del interior del país, ha aprendido que hay otras rutas por las que el mercurio entra en el país. Durante una entrevista, llama a su comerciante chino-surinamés de equipos mineros para obtener más información.`}</p>
    <p>{`El comerciante chino-surinamés afirma que ahora los chinos proveen la mayor parte del mercurio del país. Las tiendas compran contenedores de mercurio por $3000 y los venden por $3200 ―una ganancia de $200. “Si viene de China, solo tiene que pasar por un lugar”, dice el empresario. “El puerto `}{`[marítimo]`}{`”.`}</p>
    <p>{`Un estudio realizado en 2020 por el Comité Nacional de la Unión Internacional para la Conservación de la Naturaleza de los Países Bajos ((IUCN NL))`}{`[(https://www.iucn.nl/-files/groene_economie/lr_mercury_brochure_digitaal_gebruik.pdf)]`}{` arrojó que no se han interceptado remesas ilegales de mercurio procedentes de China, pero los informantes dijeron a los investigadores que los funcionarios de aduanas por lo general son sobornados para que dejen entrar en el país mercancías ilícitas.`}</p>
    <p>{`De vuelta en el “backtrack”, los cruces informales del río entre Guyana y Surinam, un surinamés traficante en bote observa el río Corentin cerca de Nieuw Nickerie. Afirma que el mercurio se lleva de Surinam a Guyana y las rutas de contrabando son muy fugaces. Hasta hace cinco meses, él mismo llevó el mercurio por el río en la dirección opuesta, de Guyana a Surinam, en envases de tamaño industrial. “La gente compra las botellas grandes... y las envasan en botellas más pequeñas para comercializarlas y venderlas más rápido”, relata el traficante.`}</p>
    <Video url="https://youtube.com/embed/wlKJb1KKJCc" mdxType="Video" />
    <p>{`La corrupción, los rápidos cambios en el modus operandi y la falta de recursos frustran a algunos de los bienintencionados agentes de orden público de Surinam. “La frontera está abierta”, dice un funcionario local del municipio de Nieuw Nickerie. Los múltiples puntos de cruce ofrecen amplias oportunidades a los contrabandistas. En las riberas surinamesas del río hay muchas rutas internas y riachuelos informales que facilitan la entrada. No podemos tener los ojos puestos en todos los lugares a la vez”, dice, y añade que los contrabandistas “se están volviendo cada vez más inteligentes”.`}</p>
    <blockquote>
      <p parentName="blockquote">{`No podemos tener los ojos puestos en todos los lugares a la vez”, dice, y añade que los contrabandistas “se están volviendo cada vez más inteligentes”.`}</p>
    </blockquote>
    <hr></hr>
    <p>{`Capy, el cimarrón propietario de una mina cerca de Brownsweg, la ha dirigido durante 15 años. Como la mayoría de los mineros entrevistados para este informe, espera abandonar el mercurio, pero le falta la orientación adecuada. “Estamos dispuestos. Si dicen que hay otra manera, queremos trabajar con ella”, dice Capy. “Pero ellos `}{`[el gobierno]`}{` no dicen nada; no nos enseñan nada”.`}</p>
    <p>{`El argumento de los mineros de carrera se hace eco en Naarendorp. “Nadie puede estar en contra de Minamata”, dice, “pero en el momento en que dices que vamos a prohibir algo, tienes que ofrecer una alternativa”.`}</p>
    <Video url="https://youtube.com/embed/9uuO-lK0GnE" mdxType="Video" />
    <p>{`Muchos de los mineros del interior son trabajadores hábiles, pero sin formación, según Naarendorp. Ella sostiene que además de instruir a los mineros también es necesario convencerlos de métodos alternativos o seguros, pero estos suelen ser muy costosos o de difícil acceso. “Todos queremos ser más responsables con el medioambiente”, declara, “pero debe ser asequible”.`}</p>
    <p>{`Capy admite que necesitan comprar mercurio en el mercado negro. “No hay un lugar específico donde se pueda comprar, porque está prohibido, al igual que la cocaína, la marihuana y el éxtasis. Este tipo de mercancía solo se encuentran en la calle porque están prohibidas”.`}</p>
    <p><em parentName="p">{`*`}{`Algunos nombres han sido cambiados para proteger las identidades de los entrevistados.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      